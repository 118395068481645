/*
    字段名	字段说明	所属组件	数据类型
    field	组件id	公共	String
    type	组件类型	公共	String
    label	组件标签	公共	String
    span	组件表单栅格	公共	int
    defaultValue	默认值	公共	String
    required	是否必填	公共	Boolean
    isQueryField	是否查询项	公共	Boolean
    explain	字段说明	公共	String
    compsGroupId	所属组件组id	公共	String
    placeholder	占位提示/文字说明/图片样例	单行文本/多行文本/日期选择/文字说明/图片示例	String
    maxLength	最大长度	单行文本/多行文本	int
    rules	校验规则	单行文本	String
    documentOptions	证件类型选项	单行文本	Object Array
    documentDefaultValue	证件类型默认选中项	单行文本	String
    minRows	最小行数	多行文本	int
    maxRows	最大行数	多行文本	int
    options	选项	单选/多选/下拉选择	Object Array
    multiple	是否多选	下拉选择	Boolean
    relatedComps	关联组件	单选/多选/下拉选择	Object Array
    fileSize	上传文件最大限制	单图片上传/多图片上传/附件上传	int
    sizeUnit	上传文件最大限制单位	单图片上传/多图片上传/附件上传	String
    cuttable	是否可裁剪	单图片上传	Boolean
    limit	上传文件数量限制	多图片上传/附件上传	int
    format	日期格式	日期选择	String
    autoGenerate	自动生成	日期选择	Boolean
    fontSize	字体大小	文字说明	int
    fontColor	字体颜色	文字说明	String
    fontWeight	字体粗细	文字说明	String
    fontStyle	字体样式	文字说明	String
    textDecoration	文本修饰	文字说明	String
    textAlign	文字位置	文字说明	String
    hyperlinks	是否为超链接	文字说明	Boolean
    linkAddress	链接地址	文字说明	String
    popUpWindow	是否弹窗	文字说明	Boolean
    popUpWindowAddress	弹窗地址	文字说明	String
    addressLevel	地址级别	地址选择	int
    startAddressLevel	开始级别	地址选择	int
    startAddress	开始地址	地址选择	Object Array
    searchSwitchOpen 是否可筛选	单行文本/下拉选择/单选框组/多选框组/日期选择  String '1'开 '0'关
 */

// 值是Boolean类型的字段 ↓
const keysBoolean = ['required', 'isQueryField', 'multiple', 'cuttable', 'autoGenerate', 'hyperlinks', 'popUpWindow']
// 值是Object Array类型的字段 ↓
const keysObjArr = ['defaultValue', 'documentOptions', 'options', 'relatedComps', 'startAddress']
// 用户可提交值的组件 ↓
const compsStoreVal = ['input', 'textarea', 'select', 'radio', 'checkbox', 'upload', 'miupload', 'fileload', 'date', 'address']
// 值是Object Array类型的组件 ↓
const compsValObjArr = ['select', 'checkbox', 'miupload', 'fileload', 'address']
// 可修改选项且有默认值的组件 ↓
const compsChangeOptions = ['select', 'radio', 'checkbox']
// 不显示在表格中的组件 ↓
const compsTableHide = ['compsGroup', 'divider', 'explain', 'imgExample']

const comps = [
    {
        type: 'compsGroup',
        label: '表单组',
    },
    {
        type: 'input',
        label: '单行文本',
        span: 24,
        defaultValue: '',
        required: false,
        isQueryField: false,
        explain: '',
        compsGroupId: '',
        placeholder: '请输入',
        maxLength: 50,
        rules: 'text',
        documentOptions: [],
        documentDefaultValue: '',
        searchSwitchOpen: '0',
    },
    {
        type: 'textarea',
        label: '多行文本',
        span: 24,
        defaultValue: '',
        required: false,
        isQueryField: false,
        explain: '',
        compsGroupId: '',
        placeholder: '请输入',
        maxLength: 255,
        minRows: 2,
        maxRows: 4,
    },
    {
        type: 'divider',
        label: '分隔线',
    },
    {
        type: 'select',
        label: '下拉选择',
        span: 24,
        defaultValue: '',
        required: false,
        isQueryField: false,
        explain: '',
        compsGroupId: '',
        placeholder: '请选择',
        options: [
            {
                label: '选项一',
                value: '0100'
            },
            {
                label: '选项二',
                value: '0200'
            }
        ],
        multiple: false,
        relatedComps: [],
        searchSwitchOpen: '0',
    },
    {
        type: 'radio',
        label: '单选框组',
        span: 24,
        defaultValue: '',
        required: false,
        isQueryField: false,
        explain: '',
        compsGroupId: '',
        options: [
            {
                label: '选项一',
                value: '0100'
            },
            {
                label: '选项二',
                value: '0200'
            }
        ],
        relatedComps: [],
        searchSwitchOpen: '0',
    },
    {
        type: 'checkbox',
        label: '多选框组',
        span: 24,
        defaultValue: [],
        required: false,
        isQueryField: false,
        explain: '',
        compsGroupId: '',
        options: [
            {
                label: '选项一',
                value: '0100'
            },
            {
                label: '选项二',
                value: '0200'
            }
        ],
        relatedComps: [],
        searchSwitchOpen: '0',
    },
    {
        type: 'upload',
        label: '单图片上传',
        span: 24,
        required: false,
        explain: '',
        compsGroupId: '',
        fileSize: 20,
        sizeUnit: 'MB',
        cuttable: false,
    },
    {
        type: 'miupload',
        label: '多图片上传',
        span: 24,
        required: false,
        explain: '',
        compsGroupId: '',
        fileSize: 20,
        sizeUnit: 'MB',
        limit: 2,
    },
    {
        type: 'fileload',
        label: '附件上传',
        span: 24,
        required: false,
        explain: '',
        compsGroupId: '',
        placeholder: '点击上传',
        fileSize: 20,
        sizeUnit: 'MB',
        limit: 1,
    },
    {
        type: 'date',
        label: '日期选择',
        defaultValue: '',
        span: 24,
        required: false,
        isQueryField: false,
        explain: '',
        compsGroupId: '',
        placeholder: '请选择日期',
        format: 'yyyy-MM-dd',
        autoGenerate: false,
        searchSwitchOpen: '0',
    },
    {
        type: 'address',
        label: '地址选择',
        span: 24,
        defaultValue: [],
        required: false,
        isQueryField: false,
        explain: '',
        compsGroupId: '',
        placeholder: '请选择地址',
        addressLevel: 3,
        startAddressLevel: 1,
        startAddress: [],
    },
    {
        type: 'explain',
        label: '文字说明',
        span: 24,
        compsGroupId: '',
        placeholder: 'Just write something',
        fontSize: 15,
        fontColor: '#303133',
        fontWeight: 'normal',
        fontStyle: 'normal',
        textDecoration: 'none',
        textAlign: 'left',
        hyperlinks: false,
        linkAddress: '',
        popUpWindow: false,
        popUpWindowAddress: '',
    },
    {
        type: 'imgExample',
        label: '图片示例',
        span: 12,
        explain: '',
        compsGroupId: '',
        placeholder: '',
    },
]

// 证件类型下拉框选项列表
const documentOptions = [
    {
        value: 'idCardNo',
        label: '居民身份证'
    },
    {
        value: 'passportChina',
        label: '中国护照'
    },
    // ,{
    //   value: 'passportForeign',
    //   label: '外国护照'
    // }
    {
        value: 'idCardXianggang',
        label: '香港居民身份证'
    },
    {
        value: 'idCardAomen',
        label: '澳门居民身份证'
    },
    {
        value: 'idCardTaiwan',
        label: '台湾居民身份证'
    }
    // ,{
    //   value: 'others',
    //   label: '其他'
    // }
]

export {
    comps,
    documentOptions,
    keysBoolean,
    keysObjArr,
    compsStoreVal,
    compsValObjArr,
    compsChangeOptions,
    compsTableHide
}
